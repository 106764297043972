export default class ModuleEntry {
	id: string;
	instance: Object | undefined;
	dependencyIds: string[] | undefined;
	factory: Promise<(any) => any> | (any) => any | undefined;

	constructor(moduleId){
		this.id = moduleId;
	}

	clearInstance() {
		this.instance = undefined;
	}
}