/** @flow */
import type { RenderDto } from '../../types/preview-types';
import type { ITransformer } from '../../../types/preview-types';

export default class ReactToDomRenderer implements ITransformer {
	name = "rendering to dom";

	currentReactDom: object;

	run({ value, scope, preferredVersions }: RenderDto) {
		const ReactDom = scope['ReactDom'] || scope['ReactDOM'];
		if (!ReactDom) {
			throw new ReferenceError('ReactDom is not defined');
		}

		this._updateReactDom(ReactDom);

		try{
			ReactDom.render(value, this._getAnchor());
		} catch(err){
			const reactVersion = ReactDom.version || preferredVersions['react-dom'];
			const versionMessage = reactVersion ? `Version of 'react-dom': [${reactVersion}]\n\n` : '';

			throw new Error(`${versionMessage}${err.message}`);
		}
	}

	onUnmount() {
		if(!this.currentReactDom) return;

		const anchor = this._getAnchor();
		this.currentReactDom.unmountComponentAtNode(anchor);
	}

	_updateReactDom(next: object) {
		const prev = this.currentReactDom;
	
		if(prev === next){
			return;
		}

		this.onUnmount();
		this.currentReactDom = next;
	}

	_getAnchor() {
		return window.document.getElementById("anchor")
	}
}
