import { ImportMap } from '@bit/bit.utils.ast.import-parser';

export default class TargetComponentImports implements ITransformer {
	name = "adding import for target component";

	constructor(options) {
		this.applyOptions(options);
	}

	applyOptions(options) {
		this._options = options;
	}

	run({ scope = {}, targetComponent }: RenderDto) {
		//don't allow mix of default and non-default
		if (0 < Object.keys(scope).length) {
			return {};
		}

		return {
			scope: {
				...scope,
				[targetComponent.id]: this.generateImportMap(targetComponent)
			}
		}
	}

	generateImportMap(targetComponent) {
		const { _options = {} } = this;
		const { importOptions } = _options;

		const importMap = new ImportMap(importOptions);

		if (!!targetComponent.name) {
			importMap.addDefault(targetComponent.name);
		}

		return importMap;
	}
}