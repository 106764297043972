export class DiInstanciationError extends Error {
	name = 'DI instanciation error';
	constructor(componentName, innerError) {
		const message = `Error trying to _create a new instance_ of [[${componentName}]]:\n${innerError.toString()}`;
		super(message);
	}
}
export class DiFactoryError extends Error {
	name = 'DI factory error';
	constructor(componentName, innerError) {
		const message = `Error trying to _build factory_ for [[${componentName}]]:\n${innerError.toString()}`;
		super(message);
	}
}