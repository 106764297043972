export default class Pipe {
	constructor(transformers) {
		this.transformers = transformers;
	}

	run(initialValue) {
		return this.transformers.reduce(this._reducer, initialValue);
	}

	async _reducer(prevVal = {}, transformer) {
		const actualPrevVal = await prevVal;

		try {
			const result =
				await transformer.run(actualPrevVal);

			return {
				...actualPrevVal,
				...(result || {})
			};
		} catch (err) {
			var error = typeof err === "string" ? new Error(err) : err;

			error.transformer = transformer.name
			throw error;
		}
	}
}

//async await FTW!