import type { RenderDto } from '../../../types/preview-types';
import type { ITransformer } from '../../../types/preview-types';

export type CopyInstruction = { copyFrom: string, copyTo: string };

export default class VersionCopier implements ITransformer {
	_instructions: CopyInstruction[];
	name = 'copying versions';

	constructor(...CopyInstructions: CopyInstruction) {
		this._instructions = CopyInstructions;
	}

	applyOptions(...CopyInstructions: CopyInstruction){
		this._instructions = CopyInstructions;
	}

	run({ preferredVersions = {} }: RenderDto = {}) {
		const delta = {};

		this._instructions.forEach(({ copyTo, copyFrom, onlyWhenUndefined = true }) => {
			if (onlyWhenUndefined && !!preferredVersions[copyTo]) {
				return;
			}

			const copiedVersion = preferredVersions[copyFrom];
			if (!copiedVersion) return;

			delta[copyTo] = copiedVersion;
		});

		return {
			preferredVersions: {
				...preferredVersions,
				...delta,
			},
		};
	}
}
