/** @flow */
import FrameCommunicator from '@bit/bitsrc.ui.iframe.communicator';
import type { ConfigDto, CoordinatesDto } from '../types/preview-types';

const selfName = window.name

export class PreviewCommunicator {
	communicator = new FrameCommunicator({ selfFrameId: selfName, targetFrameId: undefined, isDebugging: true });

	sendLoaded() { this.communicator.sendMessage('loaded'); }
	sendReady() { this.communicator.sendMessage('ready'); }
	sendHasRendered(details: any) { this.communicator.sendMessage('hasRendered', details); }
	sendCoordinates(coordinates: CoordinatesDto) { this.communicator.sendMessage('gotCoordinates', coordinates); }

	requestModule(moduleId: string): Promise<Object> {
		return this.communicator.request('requestModule', `module-${moduleId}`, moduleId);
	}

	sendDefaultCode(code: string): void {
		this.communicator.sendMessage('gotDefaultCode', code);
	}

	executeSse(code) {
		return this.communicator.request('executeCode', 'executeResult', code);
	}

	onRender(callback: (dto: ConfigDto) => void) {
		return this.communicator.onMessage('render', callback);
	}

	onSetConfig(callback: (dto: ConfigDto) => void) {
		return this.communicator.onMessage('setConfig', callback);
	}

	onGetDefaultCode(callback: any => void) {
		return this.communicator.onMessage('getDefaultCode', callback);
	}

	onGetCoordinates(callback: any => void) {
		return this.communicator.onMessage('getCoordinates', callback);
	}

	onReload(callback: any => void) {
		return this.communicator.onMessage('reload', callback);
	}
}

const instance = new PreviewCommunicator();
export default instance;