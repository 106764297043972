import NpmId from '@bit/bitsrc.models.component.npm-id';
import BitNameId from '@bit/bitsrc.models.component.id';
import camelcase from 'camelcase';

export function toCanonicalId(rawId) {
	const parsed = NpmId.fromNpmId(rawId);
	if (parsed === undefined) return undefined;

	return parsed.toNpmId({ includeVersion: false, includeFilePath: false });
}

export function toCanonicalName(rawId, camelCaseOptions){
	const parsed = BitNameId.fromNpmId(rawId) || NpmId.fromNpmId(rawId);

	const componentName = (parsed && parsed.name) || 'ThisComponent';
	return camelcase(componentName, camelCaseOptions);
}

export function isValidNpmId(rawId){
	return NpmId.fromNpmId(rawId) !== undefined;
}

export class InvalidNpmIdError extends Error {
	constructor(invalidNpmId){
		super(`The following npm id is invalid: "${invalidNpmId}"`);

		this.name = 'InvalidNpmIdError';
	}
}