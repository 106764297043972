import Pipe from '../../utils/pipe';
import CodeGenerator from '../../utils/code-generator';

import BubleTranspiler from '../../render-helpers/transformers/buble/transpiler';
import Demodulator from '../../render-helpers/transformers/demodulator';
import DefaultImports from '../../render-helpers/transformers/default-imports';
import VersionExtractor from '../../render-helpers/transformers/version-extractor';
import DependencyInjector from '../../render-helpers/transformers/dependency-injector';
import StringExecuter from '../../render-helpers/transformers/string-executer';

import ErrorDrawer from '../../render-helpers/drawers/error-react-drawer';
import LoaderDrawer from '../../render-helpers/drawers/loader-react-drawer';

import type { ComponentData, RenderDto } from '../../types/preview-types';
import { ITransformer, IDrawer } from '../../types/preview-types';
import { toCanonicalId, toCanonicalName } from '../../utils/npm';

export default class ReactRenderer implements IRenderer {
	constructor() {
		this.executer = new Pipe([
			new BubleTranspiler(),
			new Demodulator({ exportAsReturn: false }),
			new DefaultImports({
				targetComponent: { isPromiscuous: true }
			}),
			new VersionExtractor(),
			new DependencyInjector(),
			new StringExecuter()
		]);

		this.errorDrawer = new ErrorDrawer();
		this.loader = new LoaderDrawer();
	}

	executer: ITransformer;
	drawer: IDrawer;

	onMount = () => {
		const anchor = document.getElementById('anchor');
		anchor.innerHTML = '';
	}

	render = (toRender: RenderDto): void => {
		this.loader.draw();
		this.errorDrawer.reset();

		const result = Promise.resolve(
			this.executer.run(toRender)
		);

		//draw error to the screen, but still reject the returned promise.
		result
			.catch(err => this.errorDrawer.draw(err))
			.then(this.loader.reset);

		return result;
	};

	generateDefaultCode(component: ComponentData = {}): string {
		const defaultName = toCanonicalName(component.id);
		const moduleId = toCanonicalId(component.id);

		return [
			CodeGenerator.generateImport({ defaultName: defaultName, moduleId: moduleId }),
		].join('\n');
	}
}