import type { ITransformer } from '../../types/preview-types';
import { isValidNpmId, InvalidNpmIdError } from '../../../utils/npm';

export default class ScopeValidator implements ITransformer {
	name = 'validating scope';

	async run({ scope = {} }: RenderDto): RenderDto {
		Object.keys(scope).forEach(key => {
			if (!isValidNpmId(key)) {
				throw new InvalidNpmIdError(key);
			}
		});
	}
}