/** @flow */
import NpmId from '@bit/bitsrc.models.component.npm-id';
import { diff as objDiff } from 'just-diff';
import timeoutWrap from '@bit/bit.utils.timing.timeout-wrap';
import moduleGetter from './module-getter';
import { executeImpureDefine } from '@bit/bit.javascript.amd.define';
import magickModuleFactories from './magic-modules';
import { BUNDLE_TIMEOUT } from '../constants';
import ModuleEntry from './module-entry';

import { ModuleFetchError, ModuleTimeoutError } from './errors';
import { DiFactoryError, DiInstanciationError } from './di-errors';

type VersionMap = {
	[x: string]: string
}

export default class ModuleStore {
	_store: Map<string, ModuleEntry> = new Map();
	_versionMap: VersionMap = {};

	require(moduleId: string): Promise<{}> {
		const version = this._versionMap[moduleId];
		const versionedModuleId = applyVersion(moduleId, version);
		const entry = this._getOrCreateEntry(versionedModuleId);

		const promise = Promise.resolve(this._getInstance(entry));

		return timeoutWrap(promise, new ModuleTimeoutError(versionedModuleId), BUNDLE_TIMEOUT);
	}

	setVersionMap(versionMap: VersionMap) {
		const mapDiff = objDiff(this._versionMap, versionMap);
		const hasChanged = 0 < mapDiff.length;

		this._versionMap = versionMap;

		if (hasChanged) {
			this._store.forEach(entry => entry.clearInstance());
		}
	}

	_getInstance(entry: ModuleEntry): Promise<{}> {
		if (entry.instance) return entry.instance;

		entry.instance = this._instanciate(entry)
			.then(instance => { entry.instance = instance; return instance; });

		return entry.instance;
	}

	async _instanciate(entry: ModuleEntry): Promise<{}> {
		return this._getFactoryInstance(entry)
			.then(factory => {
				const { dependenciesPromises, magicModules } = this._requireDependencies(entry);

				return Promise.all(dependenciesPromises)
					.then(dependencies => {
						try {
							return factory.apply({}, dependencies)
						} catch (factoryError) {
							throw new DiInstanciationError(entry.id, factoryError);
						}
					})
					.then(
						//some modules define themselves ontop of an 'exports' dependency, instead of just using return
						//go figure ¯\_(ツ)_/¯
						result => {
							return magicModules.exports || result
						}
					);
			});
	}

	_getFactoryInstance(entry: ModuleEntry): Promise<{}> {
		if (!!entry.factory) return Promise.resolve(entry.factory);

		return entry.factory =
			moduleGetter.request(entry.id)
				.then(({ moduleId, file, error }) => {
					if (!!error) {
						const fetchError = new ModuleFetchError(entry.id, error);

						return entry.factory = Promise.reject(fetchError);
					}

					return { file };
				})
				.then(({ file }) => {
					try {
						return executeImpureDefine(file)
					} catch (defineError) {
						throw new DiFactoryError(entry.id, defineError);
					}
				})
				.then(({ factory, dependenciesIds }) => {
					entry.factory = factory;
					entry.dependencyIds = dependenciesIds;

					return factory;
				});
	}

	_getOrCreateEntry(moduleId: string): ModuleEntry {
		const { _store } = this;

		const existingEntry = _store.get(moduleId);
		if (!!existingEntry) return existingEntry;

		const newEntry = new ModuleEntry(moduleId);
		_store.set(moduleId, newEntry);

		return newEntry;
	}

	_requireDependencies(entry: ModuleEntry): { dependenciesPromises: Promise<{}>[], magicModules: { [string]: any } } {
		const { dependencyIds = [] } = entry;
		const magicModules = {};

		const dependenciesPromises = dependencyIds.map(depId => {
			const magickModuleFactory = magickModuleFactories.get(depId);
			if (!!magickModuleFactory) {
				magicModules[depId] = magickModuleFactory(entry);
				return magicModules[depId];
			}

			return this.require(depId);
		});

		return { dependenciesPromises, magicModules };
	}
}

function applyVersion(npmId, version){
	const parsed = NpmId.fromNpmId(npmId);

	if(!!parsed.version) return npmId;

	parsed.version = version;
	return parsed.toNpmId();
}
