import NpmId from '@bit/bitsrc.models.component.npm-id';
import { InvalidNpmIdError } from '../../../utils/npm';

import { ITransformer } from '../../../types/preview-types';
import type { RenderDto } from '../../../types/preview-types';

export default class VersionExtractor implements ITransformer {
	name = "extracting versions from dependencies";

	run({ scope = {}, preferredVersions = {}, targetComponent }: RenderDto): RenderDto {
		const versionFromScope = {};

		const scopeDependencies = Object.keys(scope);		
		if(targetComponent && targetComponent.id) {
			scopeDependencies.unshift(targetComponent.id)
		}

		scopeDependencies
			.forEach(depId => {
				
				const parsed = NpmId.fromNpmId(depId);
				if (!parsed) throw new InvalidNpmIdError(depId);
				if (!parsed.version) return;
				
				const baseName = parsed.toNpmId({ includeVersion: false });
				const version = parsed.version;

				versionFromScope[baseName] = version;
			});

		return {
			preferredVersions: {
				...preferredVersions,
				...versionFromScope
			}
		}
	}
}
