/** @flow */
import type IRenderer from '../../renderer';
import type { RenderDto } from '../../types/preview-types';

import DependenciesMock from '../../render-helpers/transformers/dependencies-mock';
import ServersideExecuter from '../../render-helpers/transformers/server-side-executer';
import Pipe from '../../utils/pipe';
import { ITransformer, IDrawer } from '../../types/preview-types';
import Stringifier from '../../render-helpers/transformers/stringifier';
import Reactifier from '../../render-helpers/transformers/reactifier';
import ReactToDomRenderer from '../../render-helpers/transformers/react-to-dom-renderer';

import ErrorDrawer from '../../render-helpers/drawers/error-react-drawer';
import LoaderDrawer from '../../render-helpers/drawers/loader-react-drawer';

export default class NodeJSRenderer implements IRenderer {
	name = 'NodeJS module';

	executer: ITransformer;
	drawer: IDrawer;
	_reactToDomRenderer;

	constructor() {
		this._reactToDomRenderer = new ReactToDomRenderer();


		this.executer = new Pipe([
			new DependenciesMock({ 'lodash': 'lodash' }),
			new ServersideExecuter(),
			new Stringifier(),
			new Reactifier(),
			this._reactToDomRenderer
		]);

		// this.drawer = new ReactDrawer();
		this.errorDrawer = new ErrorDrawer();
		this.loader = new LoaderDrawer();
	}

	onUnmount = () => {
		this._reactToDomRenderer.onUnmount();
	}

	render = (toRender: RenderDto) => {
		this.loader.draw();
		this.errorDrawer.reset();

		const result = Promise.resolve(
			this.executer.run(toRender)
		);

		//draw error to the screen, but still reject the returned promise.
		result
			.catch(err => this.errorDrawer.draw(err))
			.then(this.loader.reset);

		return result;
	};
}
