/** @flow */

import { ITransformer } from '../../types/preview-types';
import type { RenderDto } from '../../types/preview-types';
import previewCommunicator from '../../utils/preview-communicator';

// const SERVER_PATH = 'https://api-stg.bitsrc.io/sse/compile';

export default class serversideExecuter implements ITransformer {
	name = "executing";

	async run({ value, scope, dependenciesOrder }: RenderDto): RenderDto {
		const response = await previewCommunicator.executeSse(value);

		// const response = await post(SERVER_PATH, {
		// code: value,
		// packages: scope,
		// });

		const { data: { payload } } = response;

		return {
			value: payload,
		};
	}

	generateDefaultCode(component = {}) {
		return '';
	}
}