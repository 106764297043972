/** @flow */
import previewCommunicator from '../utils/preview-communicator';

export class ModuleGetter {
	requests: Map<string, Promise<Object>> = new Map();

	request(moduleId: string) {
		const { requests } = this;

		const existing = requests.get(moduleId);
		if (!!existing) return existing;

		const promise = previewCommunicator.requestModule(moduleId)
			.catch(err => ({ error: err }));


		requests.set(moduleId, promise);
		promise.then(() => requests.delete(moduleId), () => {});

		return promise;
	}
}

export default new ModuleGetter();