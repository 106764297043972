export const INNER_IFRAME_ID = "inside-preview";

export const BUNDLE_TIMEOUT = 60 * 1000;

export const rendererNames = {
	react: 'bit.rendering/renderers/react@0.0.1',
	sse: 'bit.rendering/renderers/sse@0.0.1',
	vanilla: 'bit.rendering/renderers/vanilla@0.0.1'
};

export const DEFAULT_RENDERER = rendererNames.react;