import { ImportMap } from '@bit/bit.utils.ast.import-parser';
export function reactImport() {
	const reactImport = new ImportMap();
	reactImport.addNamespace('React');
	return {
		'react': reactImport
	};
}
export function reactDomImport() {
	const reactDomImport = new ImportMap();
	reactDomImport.addNamespace('ReactDom');
	return {
		'react-dom': reactDomImport
	};
}