
export default class LoadingDrawer implements IDrawer {
	draw = () => {
		document.body.classList.add('__playground-loading__');
	}

	reset = () => {
		document.body.classList.remove('__playground-loading__');
	}
}


