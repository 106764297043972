/** @flow */
import { ITransformer } from '../../types/preview-types';
import type { RenderDto } from '../../types/preview-types';

export default class Stringifier implements ITransformer {
	name = "stringifying";

	run(params: RenderDto) {
		const { value } = params;
		return {
			value: this.transform(value)
		}
	}

	transform(val: any) : string {
		switch (val) {
			case undefined:
				return "<<undefined>>"
			case null:
				return "<<NULL>>";
			default: break;
		}

		switch (typeof val) {
			case "string":
				return `"${val}"`
			case "number":
				return val;
			default:
				return val.toString();
		}
	}
}