export default class CodeGenerator {
	static tab = '\t';

	static generateImport({ defaultName, nonDefaults, namespace, moduleId }): string {
		const toImport = [];
		if (defaultName) {
			toImport.push(defaultName);
		}
		if (namespace) {
			toImport.push(`* as ${namespace}`);
		}
		if (nonDefaults) {
			toImport.push(`{ ${nonDefaults.join(', ')} }`);
		}

		return `import ${toImport.join(', ')} from '${moduleId}';`
	}

	static generateModule({ imports, defaultExport }): string {
		const textImports = imports.map(CodeGenerator.generateImport);

		return [
			...textImports,
			'',
			'export default (',
			CodeGenerator.tab + defaultExport,
			')'
		].join('\n');
	}
}