import { toCanonicalId } from '../../../utils/npm';

export default class DefaultImports implements ITransformer {
	name = "adding default imports";

	constructor(options = {}) {
		this._options = options;
	}

	applyOptions(options) {
		this._options = {
			...this._options,
			...options
		}
	}

	run({ scope = {} }: RenderDto) {
		const { imports = {} } = this._options;
		const newScope = {};

		const existingImports = new Set(
			Object.keys(scope)
				.map(x => toCanonicalId(x))
		);

		Object.keys(imports)
			.filter(x => !existingImports.has(toCanonicalId(x)))
			.forEach(x => newScope[x] = imports[x]);

		if (Object.keys(newScope).length === 0) return {};

		return {
			scope: {
				...newScope,
				...scope
			}
		};
	}
}