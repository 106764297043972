/** @flow */
import { ITransformer } from '../../../types/preview-types';
import type  { RenderDto } from '../../../types/preview-types';

import { parse } from 'acorn-jsx';
import MagicString from 'magic-string';

const EXPORTABLE_STATEMENTS = {
	'Literal': true, 'BlockStatement': true, 'ExpressionStatement': true, 'FunctionDeclaration': true, 'ClassDeclaration': true
}

const parseOptions = {
	ecmaVersion: 9,
	sourceType: 'module',
	// allowImportExportEverywhere: true,
	allowReturnOutsideFunction: true,
	plugins: { jsx: true },
	preserveParens: true //so we won't lose any '()'
}

export default class Modulator implements ITransformer {
	name = "modulating";

	run({ value }: RenderDto) {
		const ast = parse(value, parseOptions);
		const magicValue = new MagicString(value);

		this.exportifyCode(ast, magicValue);

		return {
			value: magicValue.toString()
		};
	}

	exportifyCode(ast, magicString: MagicString) {
		const defaultExport = getDefaultExport(ast);
		if (defaultExport) return magicString;

		const returnStatement = getTopLevelReturn(ast);
		if (returnStatement) {
			return replaceReturnWithExport(returnStatement, magicString);
		}

		const lastExportable = ast.body
			.filter(declaration => EXPORTABLE_STATEMENTS[declaration.type] === true)
			.slice(-1).pop();

		if (!!lastExportable) {
			magicString.appendLeft(lastExportable.start, "export default ");
		}
	}
}

export function replaceReturnWithExport(returnStatement, magicString: MagicString) {
	const startIdx = returnStatement.start;
	const content = returnStatement.argument;
	const endIdx = content.start;

	return magicString.overwrite(startIdx, endIdx, "export default ");
}

//'import' and 'export' may only appear at the top level
export function getDefaultExport(ast) { return ast.body.find(x => x.type === 'ExportDefaultDeclaration'); }
export function getTopLevelReturn(ast) { return ast.body.find(x => x.type === "ReturnStatement"); }
export function getImports(ast) { return ast.body.some(x => x.type === 'ImportDeclaration'); }