// import type { ModuleDefinition } from "../utils/define";

export type ComponentData = {
	id: string;
	name: string;
}

export type RenderDto = {
	value: ?string;
	targetComponent: ?ComponentData;
	scope: { packageId: ModuleDefinition | string };
	preferredVersions: {
		packageName: string; //value -> version
	},
	warning: string;
}

export const renderDefaults = {
	value: '',
	targetComponent: null,
	preferredVersions: {},
}

export function sanitizeRenderModel(rawData): RenderDto{
	const sanitized = {};
	Object.keys(rawData)
		.filter(key => renderDefaults.hasOwnProperty(key))
		.forEach(key => sanitized[key] = rawData[key] !== undefined ? rawData[key] : renderDefaults[key]);

	return sanitized;
}

// passed from editor to player
export type ConfigDto = {
	value: ?string;
	targetComponent: ?ComponentData;
	rendererId: ?string;
	preferredVersions: {
		packageName: string; //value -> version
	}
}

export type CoordinatesDto = {
	x: number;
	y: number;
	width: number;
	height: number;
	error: string;
}