import { ITransformer } from '../../types/preview-types';
import type { RenderDto } from '../../types/preview-types';
import di from '../../di';
import { ImportMap } from '@bit/bit.utils.ast.import-parser';

export default class DependencyInjector implements ITransformer {
	name = "injecting dependencies";

	async run({ scope = {}, preferredVersions = {} }: RenderDto): RenderDto {
		di.setVersionMap(preferredVersions);

		const dependencyPromises = Object.keys(scope)
			.filter(key => scope[key] instanceof ImportMap)
			.map(async depId => {
				const dependency = await di.require(depId);
				const map: ImportMap = scope[depId];

				return map.applyMapping(dependency);
			})

		const allDependnecies = await Promise.all(dependencyPromises);

		//should not conflict, because conflicts would fail in AST parsing
		const newScope = Object.assign({}, ...allDependnecies);

		return {
			scope: newScope
		};
	}
}