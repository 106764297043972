import { RenderDto, ITransformer } from '../../types/preview-types';

export default class DependencyDefinier implements ITransformer {
	name = "mocking dependencies";
	
	dependenciesIds = undefined;

	constructor(dependenciesIds) {
		this.dependenciesIds = dependenciesIds;
	}

	run({ scope = {} }: RenderDto) {
		return {
			scope: {
				...scope,
				...this.dependenciesIds
			}
		}
	}
}