import httpStatuses from 'http-status';

export default class ErrorDrawer {
	draw = (err: Error | string) => {
		document.body.classList.add('__playground-error__');

		this._setContent(this._getTransformerAnchor(), err.transformer && `Error while ${err.transformer}`);
		this._setContent(this._getNameAnchor(), err.name && `Type: ${err.name}`);
		this._setContent(this._getHttpStatusAnchor(), err.httpStatus && this._formatHttpStatus(err.httpStatus));
		this._setContent(this._getMessageAnchor(), err.message);
		this._setContent(this._getSnippetAnchor(), err.snippet);
		this._setContent(this._getStackAnchor(), err.stack && `Stack trace:\n\n${err.stack}`);
	}

	reset = () => {
		document.body.classList.remove('__playground-error__');
	}

	_setContent(node, content){
		if(!node) return;
		if(!content){ 
			node.style.display = 'none';
			return;
		}

		node.style.display = 'block';
		node.textContent = content;
	}

	_formatHttpStatus(httpStatus){
		if(!httpStatus) return undefined;

		const statusMessage = httpStatuses[httpStatus];
		const statusText = statusMessage ? ` - "${statusMessage}"` : '';
		
		return `HTTP status\n\n${httpStatus}${statusText}`;
	}

	_getHttpStatusAnchor(){
		return document.getElementById("__playground-error__-httpStatus");
	}

	_getTransformerAnchor() {
		return document.getElementById("__playground-error__-transformer")
	}
	_getNameAnchor() {
		return document.getElementById("__playground-error__-name");
	}
	_getMessageAnchor() {
		return document.getElementById("__playground-error__-message");
	}
	_getSnippetAnchor() {
		return document.getElementById("__playground-error__-snippet");
	}
	_getStackAnchor() {
		return document.getElementById("__playground-error__-stack");
	}
}
