import type { ConfigDto } from './types/preview-types';

import ReactRenderer from './renderers/react';
import previewCommunicator from './utils/preview-communicator'
import NodeJSRenderer from './renderers/nodejs';
import VanillaRenderer from './renderers/vanilla/vanilla-renderer';

import { sanitizeRenderModel } from './types/preview-types'
import { rendererNames, DEFAULT_RENDERER } from './constants';
import { debounceWrap } from './utils/debouncer';

const renderers = {
	[rendererNames.react]: new ReactRenderer(),
	[rendererNames.sse]: new NodeJSRenderer(),
	[rendererNames.vanilla]: new VanillaRenderer()
};

class PreviewFrame {
	renderer = renderers[DEFAULT_RENDERER];
	state: ConfigDto = {};
	
	constructor(){
		this._render = debounceWrap(this._render, this);
	}

	run() {
		previewCommunicator.onSetConfig(this.handleConfig.bind(this));
		previewCommunicator.onGetDefaultCode(this.handleDefaultCodeRequest.bind(this));
		previewCommunicator.onRender(this.handleRender.bind(this));
		previewCommunicator.onGetCoordinates(this.handleGetCoordinates.bind(this));
		previewCommunicator.onReload(this.hanldeReload.bind(this));

		previewCommunicator.sendLoaded();
	}

	handleConfig(configDto: ConfigDto) {
		if(!configDto){
			console.warn('playground renderer: got empty config');
			return;
		}
		this._setState(configDto);

		previewCommunicator.sendReady();
	}

	handleDefaultCodeRequest(config: ConfigDto) {
		this._setState(config);
		const { targetComponent } = this.state;
		
		if(!this.renderer) {
			previewCommunicator.sendDefaultCode('');
			return; 
		}

		const defaultCode = this.renderer.generateDefaultCode(targetComponent);

		previewCommunicator.sendDefaultCode(defaultCode);
	}

	handleRender(dto: ConfigDto) {
		this._setState(dto);
		this._render();
	}

	handleGetCoordinates() {
		const anchor = document.getElementById("anchor");
		if (!anchor) {
			previewCommunicator.sendCoordinates({ error: "DOM Error: rendered results not found" });
		}

		const pos = anchor && anchor.getBoundingClientRect();
		const coordinates = pos ? { x: pos.x, y: pos.y, width: pos.width, height: pos.height } : undefined;

		previewCommunicator.sendCoordinates(coordinates);
	}

	hanldeReload() {
		window.location.reload();
	}

	_setState(dto: ConfigDto = {}) {
		const { rendererId } = dto;
		if (rendererId && this.state !== rendererId) {
			this._changeRenderer(rendererId);
		}

		const newState = {
			...this.state,
			...dto
		};

		return this.state = newState;
	}

	_changeRenderer(id) {
		const newRenderer = renderers[id];
		if (!newRenderer) { throw new Error(`Change renderer: no such renderer '${id}'`); }

		const prevRenderer = this.renderer;
		prevRenderer && prevRenderer.onUnmount && prevRenderer.onUnmount();

		this.renderer = newRenderer;
		newRenderer.onMount && newRenderer.onMount();
	}

	_render() {
		const renderDto = sanitizeRenderModel(this.state);
		
		const promise = this.renderer.render(renderDto);

		return promise.then(this._renderSuccess, this.renderCatch);
	}
	
	_renderSuccess({ warning }) {
		previewCommunicator.sendHasRendered({ error: warning });
	}

	_renderCatch(error = "unkown error") {
		previewCommunicator.sendHasRendered({
			error: error.toString()
		});
	}
}

(new PreviewFrame()).run();
