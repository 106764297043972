/** @flow */
import { transform } from 'buble';
import { ITransformer } from '../../../types/preview-types';
import type { RenderDto } from '../../../types/preview-types';


export default class BubleTranspiler implements ITransformer {
	options = {
		transforms: {
			modules: false,
			// dangerousForOf
			dangerousTaggedTemplateString: true,
			//consider turning template string completely off using:
			// templateString: false,
		},
		objectAssign: 'Object.assign',
	};
	name = "transpiling with Buble";

	run(params: RenderDto) {
		const { value } = params;
		if (!value) return {};

		const transformed = transform(value, this.options);

		return {
			value: transformed.code
		};
	}
}