/** @flow */
import ModuleEntry from './module-entry';

type factory = (entry: ModuleEntry) => ({});
const magickModuleFactories: Map<string, factory> = new Map();

const exportsFactory = (entry: ModuleEntry) => ({});
const moduleFactory = (entry: ModuleEntry) => ({ id: entry.id, uri: undefined });
const requireFactory = (entry: ModuleEntry) => {
	throw new Error("'require', as a dependency, is not supported");
};

magickModuleFactories.set('exports', exportsFactory);
magickModuleFactories.set('module', moduleFactory);
magickModuleFactories.set('require', requireFactory);

export default magickModuleFactories;