import type { ComponentData, RenderDto } from '../../types/preview-types';

import Pipe from '../../utils/pipe';

import Modulator from '../../render-helpers/transformers/modulator';
import BubleTranspiler from '../../render-helpers/transformers/buble/transpiler';
import Demodulator from '../../render-helpers/transformers/demodulator';
import ScopeValidator from '../../render-helpers/transformers/scope-validator'
import ImportTargetComponent from '../../render-helpers/transformers/import-target-component.js';
import DefaultImports from '../../render-helpers/transformers/default-imports';
import DependencyInjector from '../../render-helpers/transformers/dependency-injector';
import VersionExtractor from '../../render-helpers/transformers/version-extractor';
import VersionCopier from '../../render-helpers/transformers/version-copier';
import StringExecuter from '../../render-helpers/transformers/string-executer';
import Reactifier from '../../render-helpers/transformers/reactifier';
import ReactToDomRenderer from '../../render-helpers/transformers/react-to-dom-renderer';

import ErrorDrawer from '../../render-helpers/drawers/error-react-drawer';
import LoaderDrawer from '../../render-helpers/drawers/loader-react-drawer';

import { toCanonicalId, toCanonicalName } from '../../utils/npm';
import CodeGenerator from '../../utils/code-generator';
import { ITransformer } from '../../types/preview-types';
import { reactImport, reactDomImport } from './dependencies';

const CAMELCASE_OPTIONS = { pascalCase: true };

export default class ReactRenderer implements IRenderer {
	constructor() {
		this._reactToDomRenderer = new ReactToDomRenderer();

		this.executer = new Pipe([
			new Modulator(),
			new BubleTranspiler(),
			new Demodulator({ exportAsReturn: true }),

			new ScopeValidator(),
			new ImportTargetComponent({ importOptions: { isPromiscuous: true } }),
			new DefaultImports({
				imports: {
					...reactImport(),
					...reactDomImport(),
				}
			}),
			new VersionExtractor(),
			new VersionCopier({copyFrom: 'react', copyTo: 'react-dom', onlyWhenUndefined: true}),
			new DependencyInjector(),

			new StringExecuter(),
			new Reactifier(),
			this._reactToDomRenderer
		]);

		this.errorDrawer = new ErrorDrawer();
		this.loader = new LoaderDrawer();
	}

	executer: ITransformer;

	onUnmount = () => {
		this._reactToDomRenderer.onUnmount();
	}

	render = (toRender: RenderDto): void => {
		this.loader.draw();
		this.errorDrawer.reset();

		const result = Promise.resolve(
			this.executer.run(toRender)
		)

		//draw error to the screen, but still reject the returned promise.
		result
			.catch(err => this.errorDrawer.draw(err))
			.then(this.loader.reset);

		return result;
	};

	generateDefaultCode(component: ComponentData = {}): string {
		const defaultName = toCanonicalName(component.id, CAMELCASE_OPTIONS);
		const moduleId = toCanonicalId(component.id);
	
		return CodeGenerator.generateModule({
			imports: [{ defaultName: defaultName, moduleId: moduleId }],
			defaultExport: `<${defaultName}/>`,
		});
	}
}
