/** @flow */
import { parse } from 'acorn-jsx';
import MagicString from 'magic-string';
import { parseImport, getDefaultExport, getImports } from '@bit/bit.utils.ast.import-parser';

import { ITransformer } from '../../../types/preview-types';
import type { RenderDto } from '../../../types/preview-types';

type modulatorOptions = {
	exportAsReturn: ?boolean
}

const parseOptions = {
	ecmaVersion: 9,
	sourceType: 'module',
	allowReturnOutsideFunction: true,
	preserveParens: true //so we won't lose any '()'
	// allowImportExportEverywhere: true,
	// plugins: { jsx: true }
}

export default class Demodulator implements ITransformer {
	name = "modulating";

	options: modulatorOptions;

	constructor(options: modulatorOptions) {
		this.applyOptions(options);
	}

	applyOptions(options: modulatorOptions) {
		this.options = {
			...this.options,
			...options
		}
	}

	run({ value, scope }: RenderDto) {
		const ast = parse(value, parseOptions);
		const magicValue = new MagicString(value);

		this.returnifyCode(ast, magicValue);
		const imports = this.sliceImports(ast, magicValue);

		return {
			value: magicValue.toString(),
			scope: {
				...scope,
				...imports
			}
		};
	}

	returnifyCode(ast, magicString: MagicString) {
		const defaultExport = getDefaultExport(ast);
		if (!defaultExport) return;

		const replacment = this.options.exportAsReturn ? "return " : "";

		replaceExport(defaultExport, magicString, replacment);
	}

	sliceImports(ast, magicString: MagicString) {
		const imports = getImports(ast);

		imports.forEach(statement => magicString.remove(statement.start, statement.end))

		const parsedImports = {};
		imports.forEach(statement => {
			const { moduleId, map } = parseImport(statement);
			parsedImports[moduleId] = map;
		});

		return parsedImports;
	}
}

export function replaceExport(importStatement, magicString: MagicString, replacement: string) {
	const startIdx = importStatement.start;
	const content = importStatement.declaration;
	const endIdx = content.start;

	return magicString.overwrite(startIdx, endIdx, replacement);
}