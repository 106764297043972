/** @flow */
import type { RenderDto } from '../../types/preview-types';
import { ITransformer } from '../../types/preview-types';
import codeExecuter from '@bit/bit.javascript.raw.executer';

export default class StringExecuter implements ITransformer {
	name = "executing code";

	run(params: RenderDto) {
		const { value, scope = {} } = params;

		const result = codeExecuter(value, { scope: scope, returnStatement: false });

		return {
			value: result
		};
	}
}