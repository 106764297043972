export class ModuleFetchError extends Error {
	name = 'Module fetch failed'
	httpStatus = undefined;

	constructor(moduleId, rawError) {
		super(`Failed fetching "${moduleId}"\n\n${ModuleFetchError.formatErrorWithCode(rawError)}`);

		if(rawError && rawError.code){
			this.httpStatus = rawError.code;
		}
	}

	static formatErrorWithCode(rawError) {
		if (!rawError) return '';

		if (typeof rawError === "string") {
			return rawError;
		}

		const { message } = rawError;

		if (message) return message;

		return rawError.toString();
	}
}

export class ModuleTimeoutError extends Error {
	constructor(moduleId) {
		super(`Timed out trying to fetch the module "${moduleId}"`);

		this.name = 'Module timeout'
	}
}