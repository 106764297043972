//** @flow */
import { ITransformer } from '../../types/preview-types';
import type { RenderDto } from '../../types/preview-types';
import { Inspector, chromeLight } from 'react-inspector';

const inspectorTheme = {
	...chromeLight,
	...{
		BASE_LINE_HEIGHT: '1.31',
		BASE_FONT_SIZE: '15px',

		TREENODE_FONT_SIZE: '15px',
		TREENODE_LINE_HEIGHT: '1.31',
		ARROW_FONT_SIZE: '15px',
	},
};

export default class Reactifier implements ITransformer {
	name = 'reactifing';

	run(params: RenderDto) {
		const { value, scope } = params;
		const React = scope['React'];
		if (!React) {
			throw new ReferenceError('React is not defined');
		}
		if (React.isValidElement(value)) {
			return undefined;
		}

		const reactified = React.createElement(
			'div',
			{ style: { padding: '17px', boxSizing: 'border-box' } },

			React.createElement(Inspector, {
				data: value,
				theme: inspectorTheme,
				expandLevel: 1,
				showNonenumerable: true,
			})
		);

		return {
			value: reactified,
			warning: 'is not a react component'
		};
	}
}
