export function debounceWrap(func, that){
	const debouncer = new Debouncer(func, that);
	return debouncer.execute.bind(debouncer);
}

export class Debouncer {
	isRequested = false;
	isExecuting = false;

	constructor(action, that) {
		this.action = action.bind(that);
	}

	execute() {
		this.isRequested = true;
		this._next();
	}

	_next() {
		if (this.isExecuting) return;
		//'dequeue'
		if (this.isRequested !== true) return;
		this.isRequested = false;
		this._start();
	}

	_start() {
		this.isExecuting = true;
		Promise.resolve(this.action())
			.catch(() => {})
			.then(this._handleFinished.bind(this));
	}

	_handleFinished() {
		this.isExecuting = false;
		this._next();
	}
}
